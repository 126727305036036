import React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { styled } from "@mui/material/styles";
import Container from "@mui/material/Container";
import { Button } from "../Button";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useNavigate } from "react-router-dom";
import AnchorLink from "react-anchor-link-smooth-scroll";
import Navigation from "../Navigation/Navigation";

export const Header = ({ showNav = true, showCTA = false }) => {
  const navigate = useNavigate();
  const smallButton = useMediaQuery("(max-width:600px)");
  return (
    <>
      <StyledAppBar component="header" elevation={2}>
        <Container>
          <Toolbar
            sx={{
              padding: "0 !important",
              height: 60,
            }}
          >
            {showNav ? <Navigation /> : false}
            <img
              src="/img/logo-campus-talents.png"
              style={{ width: "calc(140px + 10%)", cursor: "pointer" }}
              alt="Taktsoft Campus Talents"
              onClick={() => {
                navigate("/");
              }}
            ></img>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
                paddingRight: 5,
              }}
            >
              {showNav ? (
                showCTA ? (
                  <StyledAnchorLink href="#beratung">
                    {smallButton ? "Beratung" : "Beratung vereinbaren"}
                  </StyledAnchorLink>
                ) : (
                  false
                )
              ) : (
                <Button
                  onClick={() => {
                    navigate("/");
                  }}
                  variant="outlined"
                >
                  Zurück
                </Button>
              )}
            </div>
          </Toolbar>
        </Container>
      </StyledAppBar>
    </>
  );
};

const StyledAppBar = styled(AppBar)`
  flex-direction: row;
  background-color: white;
  color: black;
`;

const StyledAnchorLink = styled(AnchorLink)`
  background-color: #11acff;
  color: white;
  padding: 4px 12px;
  border-radius: 4px;
  min-width: 64px;
  font-size: 1.1rem;
  font-weight: bold;
  line-height: 1.75;
  letter-spacing: 0.02857em;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 3px 1px -2px,
    rgba(0, 0, 0, 0.14) 0px 2px 2px 0px, rgba(0, 0, 0, 0.12) 0px 1px 5px 0px;
  &:hover {
    background-color: #0a8de4;
  }
  &:active {
    transition: all 0.2s;
    box-shadow: none;
    transform: scale(0.98);
  }
`;
