export const content = {
  teaser: [
    {
      text: "Intensivkurs Softwareentwickler:in",
    },
    {
      text: '"Ready for the Job" in nur 4 Monaten',
    },
    {
      text: "Finanzierung durch Bildungsgutschein",
    },
    {
      text: "Beste Chancen auf dem Arbeitsmarkt",
    },
  ],
  whatIsABootcamp: {
    headline: "Professional Coding Bootcamp",
    columns: [
      `
Du willst Dich beruflich neu orientieren? Im Coding-Bootcamp werden Quereinsteiger:innen 
in nur vier Monaten zu gesuchten Fachkräften ausgebildet.

Du wirst durch Top-Dozent:innen und Career Coaches durchgängig betreut.
    `,
      `
Das Bootcamp besteht aus Vorträgen, Übungen, Teamprojekten und der persönlichen Abschlussarbeit - ein 
intensives und forderndes Programm. 

Deine Ergebnisse präsentierst Du dann Unternehmen, die auf der Suche 
nach IT-Fachkräften sind.
    `,
    ],
  },
  whyIsItSuccessful: {
    headline: "Warum ist das so erfolgreich?",
    columns: [
      `
Vier intensive Monate sind ausreichend, um den Sprung in einen neuen Job zu 
schaffen - die Jobchancen für unsere Absolvent:innen sind hoch.

Als Absolvent:in des Coding Bootcamps bist Du eine gut ausgebildete Fachkraft, 
kannst Dich selber weiterentwickeln und hast Dein Talent und Deine Motivation 
bewiesen - das ist das beste Bewerbungszeugnis.
    `,
    ],
  },
  howToParticipate: {
    headline: "Wie kann ich mitmachen?",
    columns: [
      `
Finanziert wird das Coding Bootcamp über Bildungsgutscheine oder ein 
persönliches Finanzierungsmodell.

Die notwendige Hardware (Macbook Pro und iPhone) wird von uns gestellt. 
Vorwissen wird nicht benötigt, ein gewisses Talent ist aber wichtig und 
eine hohe Motiviation Voraussetzung.

Bewirb Dich jetzt und wir checken gemeinsam, ob Du ein/eine 
Kandidat:in für das Bootcamp bist.
        `,
    ],
  },
  firstHandFeedback: {
    headline: "Das sagen die Teilnehmer",
    columns: [
      `
Wir bitten unsere Teilnehmer:innen regelmäßig, Ihre Erfahrungen und Eindrücke zu teilen. 
So helfen sie uns, das Bootcamp stetig zu verbessern und Interessent:innen wie Dir, sich ein 
Bild von der Maßnahme zu machen.\n
**Wie geht es Dir im Bootcamp?**\n
**Hattest Du vor dem Bootcamp schon Programmier-Erfahrung?**\n
**Wieviel Zeit investierst Du in die Ausbildung?**\n
**War es schwierig, einen Bildungsgutschein zu bekommen?**\n
Antworten auf diese und viele andere Fragen findest Du im Video.  
    `,
    ],
  },
  whatDoesItCost: {
    headline: "Es kostet meistens nichts!",
    columns: [
      `
Finanziert durch Bildungsgutscheine der Agentur für Arbeit - in der 
beruflichen Weiterbildung oder wenn Du arbeitssuchend bist.
Es gibt auch persönliche Finanzierungsmodelle mit kleinem Risiko. 
Wir unterstützen Dich gerne bei der Beantragung Deines Gutscheins!
    `,
      `
Womit Du allerdings vom ersten Tag an zahlst sind Schweiß und Tränen. Coden 
lernen ist keine Kunst, aber es verlangt Engagement. Bewirb Dich jetzt und 
wir testen Dein Talent und Deine Motivation.
    `,
    ],
  },
  whatDoWeDo: {
    headline: "Tagesablauf",
    columns: [
      `
Das Wichtigste vorneweg: Das Coding Bootcamp ist kein Spaziergang. Die 
Zeit wird sehr anstrengend, die Belohnung ist aber auch entsprechend groß.

Der Kurs teilt sich in drei Monate Lernphase und einen Monat Abschlussprojekt, 
in dem Du Deine eigene App erstellst. Die Lernphase besteht aus Vorträgen, Übungen und persönlichen 
Coachings - jeweils von 9 bis 17 Uhr.
    `,
      `
Die vierte Woche jeden Monats ist eine Team-Projektwoche, in der ihr in Kleingruppen eine 
App erstellt und das Gelernte festigt oder wiederholt. In dieser Woche bekommst 
Du auch Bewerbungs-Trainings, Karriere-Beratung und Präsentationsschulungen. 
Außerdem präsentieren sich regelmäßig Unternehmen, die auf der Suche nach Fachkräften sind. 
  `,
    ],
  },
  curriculumFrontend: {
    columns: [
      `
Woche 1: **Werkzeuge** (Terminal, Git und Github, 
VS Code), die **Javascript** Basics (Variables, Conditions, Objects,
Arrays, Functions, Testing)

Woche 2: **Gestaltung** (HTML und CSS Basics, Positioning und Responsiveness, 
Flexbox, Formulare und Gestaltungsgrundlagen)

Woche 3: **React Web Apps** (Components, Props, Higher Order Array Methods, 
React Router und State, State & State.) 

Woche 5: **APIs und Advanced React** (useEffect, Hooks, API-Methoden und 
-Beispiele, Global State Management, Refactoring und Testing)

Woche 6: **React Native Apps** (Android/iOS-Tooling, UI-Elemente und 
Layout, Inputs, Navigation und App Deployment)

Woche 7: **Mobile Device Capabilities und Services** (Sensoren, Kamera, 
Location, Contacts, Calendar, Firebase, Animation und Persistence)
`,
      `
Woche 9: **Weiterführende Themen** nach Bedarf (z.B. Analytics, Datenbanken, Authentication, 
Typescript, Notifications, End2End-Testing, Cloud, Open Source, UI-Libraries...) 

Woche 10: **Wiederholung** der wichtigsten Themen und Vorbereitung zum Abschlussprojekt.

In den **Projektwochen 4, 8 und 11**: **Agile Zusammenarbeit** (Agile mindest & 
methodolgies, Scrum artifacts & events) und **Career Coaching** (CVs, LinkedIn, 
Bewerbung und Präsentation)

Immer und immer wieder: **Eat, sleep, code. Repeat!** Neben den Übungen in
jeder Unterrichtseinheit hast Du 7 Wochen, in denen Du programmierst und 
Dich durchbeißt. Und damit Du nicht wahnsinnig wirst, ist immer eine Trainerin
oder ein Trainer an Deiner Seite.

Woche 12: Vorbereitung des **Abschluss-Projekts**.  
Woche 13-16: Deine Idee, Dein Projekt, Deine Show! 

Am letzten Tag dann: 
**Abschluss-Präsentation** Eurer Arbeit, Speed-Dating mit Unternehmen 
und unsere verdiente Abschlussfeier!
`,
    ],
  },
  curriculumFullstack: {
    columns: [
      `
Woche 1: **Werkzeuge** (Terminal, Git und Github, 
VS Code), die **Javascript** Basics (Variables, Conditions, Objects,
Arrays, Functions, Testing)

Woche 2: **Gestaltung** (HTML und CSS basics, Positioning und Responsiveness, 
Flexbox, Formulare und Gestaltungsgrundlagen)

Woche 3: **React Web Apps** (Components, Props, Higher Order Array Methods, 
React Router und State, State & State.) 

Woche 5: **APIs und Advanced React** (useEffect, Hooks, API-Methoden und 
  -Beispiele, Global State Management, Refactoring und Testing)
  
Woche 6: **Node.js & REST Apis** (JavaScript auf dem Server, Command Line 
Interfaces, REST Architektur, Hosting & Deployment, OpenAPI Standard, 
Validierung & Fehlerbehandlung)

Woche 7: **Datenbanken** (SQL & NoSQL Datenbanken, Entity 
Relationship Modelling, CRUD Operationen, Datensicherheit)
`,
      `
Woche 9: **Weiterführende Themen** nach Bedarf (z.B. Analytics, Authentication, 
  Typescript, End2End-Testing, Cloud, Open Source, UI-Libraries...) 

Woche 10: **Wiederholung** der wichtigsten Themen und Vorbereitung zum Abschlussprojekt.

In den **Projektwochen 4, 8 und 11**: **Agile Zusammenarbeit** (Agile mindest & 
methodolgies, Scrum artifacts & events) und **Career Coaching** (CVs, LinkedIn, 
Bewerbung und Präsentation)

Immer und immer wieder: **Eat, sleep, code. Repeat!** Neben den Übungen in
jeder Unterrichtseinheit hast Du 7 Wochen, in denen Du programmierst und 
Dich durchbeißt. Und damit Du nicht wahnsinnig wirst, ist immer eine Trainerin
oder ein Trainer an Deiner Seite.

Woche 12: Vorbereitung des **Abschluss-Projekts**.  
Woche 13-16: Deine Idee, Dein Projekt, Deine Show! 

Am letzten Tag dann: 
**Abschluss-Präsentation** Eurer Arbeit, Speed-Dating mit Unternehmen 
und unsere verdiente Abschlussfeier!
`,
    ],
  },
  info: {
    headline: "Infoabende",
    columns: [
      `
Du hast weitere Fragen oder möchtest die Dozent:innen kennenlernen?
Wir laden Dich zu einem unverbindlichen Online-Infoabend ein.

In 60 Minuten stellen wir uns vor, erklären, wie die Bewerbung und der Kurs abläuft und beantworten Eure Fragen.

Einen kleinen Einblick, was eigentlich Programmieren ist, gibt es auch.
    `,
    ],
  },
};
