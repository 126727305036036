import { Hero } from "../components/Hero";
import Container from "@mui/material/Container";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { VideoPlayer } from "../components/VideoPlayer/VideoPlayer";
import { Section } from "../components/Section";
import { content } from "../content/content-students";
import { Trainers } from "../components/Trainers/trainers";
import { SectionContainer } from "../components/SectionContainer";
import { Calendar } from "../components/Calendar";
import { StyledMarkdown } from "../components/StyledMarkdown/StyledMarkdown";
import { InfoForm } from "../components/InfoForm";
import { PersonalMeeting } from "../components/PersonalMeeting";
import { dates } from "../content/dates";
import { Grid } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Footer } from "../components/Footer";
import { Teaser } from "../components/Teaser";
import { PartnerBox } from "../components/PartnerBox";
import { NewsSection } from "../components/NewsSection";

function Students() {
  const contactRef = useRef(null);
  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const id = hash.replace("#", "");
      const element = document.getElementById(id);
      if (element) {
        setTimeout(() => {
          element.scrollIntoView({ behavior: "smooth" });
        }, 0);
      }
    }
  }, [hash]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <div id="intro"></div>
      {/* <NewHero
        image="/img/key-visual-crop.jpg"
        claim={`Reboot your life`}
        options={{ backgroundPosition: "70% 30%" }}
      /> */}
      <Hero
        image="/img/cropped-key-visual-AdobeStock_358964292_3200x1730.jpg"
        opacity={0.2}
        xsTop={"200px"}
        xsRight={"40px"}
        smTop={"200px"}
        smRight={"40px"}
        mdTop={"40px"}
        mdRight={"calc(15px + 4vw)"}
        backgroundPosition={"70% 30%"}
        borderRadius={0}
        // xsTop={"0px"}
        // xsLeft={"0px"}
        // xsRight={"0px"}
        // xsTextAlign={"left"}
        // smTop={"0px"}
        // smLeft={"0px"}
        // smRight={"0px"}
        // smTextAlign={"left"}
        // mdTop={"0px"}
        // mdLeft={"0px"}
        // mdRight={"0px"}
        // mdTextAlign={"left"}
        // color={"white"}
        // backgroundPosition={"70% 30%"}
      />

      <div
        style={{
          backgroundColor: "var(--secondary-color)",
          marginBottom: "20px",
        }}
      >
        <Container
          sx={{
            paddingLeft: "0 !important",
            paddingRight: "0 !important",
            paddingTop: "0 !important",
            paddingBottom: "0 !important",
          }}
        >
          <Teaser teaserItems={content.teaser} />
        </Container>
      </div>
      {/* <div>
        <Container
          sx={{
            paddingLeft: "0 !important",
            paddingRight: "0 !important",
            paddingTop: "0 !important",
            paddingBottom: "0 !important",
          }}
        >
          <p
            style={{
              // margin: "6px 0",
              textAlign: "center",
              fontSize: "0.8em",
              color: "#00000044",
            }}
          >
            Unsere Partnerfirmen
          </p>
        </Container>

        <PartnerBox />
      </div> */}
      <Container
        sx={{
          backgroundColor: "#f3f3f3",
          borderRadius: [0, 0, 2],
          paddingTop: 4,
          paddingBottom: 4,
        }}
      >
        <NewsSection></NewsSection>
      </Container>

      <Container component="main">
        <SectionContainer>
          <Section sectionData={content.whatIsABootcamp} colspan={2} />
          <Section sectionData={content.whyIsItSuccessful} />
          <Section sectionData={content.howToParticipate} />
        </SectionContainer>
        <div id="testimonials"></div>
        <Hero
          image="/img/schultuete-fruehstueck.jpg"
          // claim={`Was sagen die\nStudenten?`}
          xsTop={"300px"}
          xsLeft={"calc(10px + 5vw)"}
          xsTextAlign={"center"}
          smTop={"300px"}
          smLeft={"calc(20px + 20vw)"}
          smTextAlign={"center"}
          mdTop={"300px"}
          mdRight="50px"
          // mdRight={"calc(50px + 15vw)"}
          mdTextAlign={"center"}
          backgroundPosition={"45% 20%"}
        />

        <SectionContainer>
          <Grid item xs={12}>
            <h2>{content.firstHandFeedback.headline}</h2>
          </Grid>
          <Grid item xs={12} md={6}>
            <StyledMarkdown>
              {content.firstHandFeedback.columns[0]}
            </StyledMarkdown>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              flexWrap: "wrap",
            }}
          >
            <VideoPlayer url="https://www.youtube.com/watch?v=kYh2aAKAceU" />
            <VideoPlayer url="https://www.youtube.com/watch?v=VH-Rm6emTl8" />
          </Grid>
        </SectionContainer>
        <div id="costs"></div>
        <Hero
          image="/img/cropped-costs-next-academy-G6k_uEjXygE-unsplash_3200x1730.jpg"
          // claim={`Was\nkostet\ndas\ndenn?`}
          xsTop={"190px"}
          xsRight={"20px"}
          xsTextAlign={"right"}
          smTop={"190px"}
          smRight={"60px"}
          smTextAlign={"right"}
          mdTop={"190px"}
          mdRight={"60px"}
          mdTextAlign={"right"}
          backgroundPosition={"45% 50%"}
        />
        <SectionContainer>
          <Section sectionData={content.whatDoesItCost} colspan={2} />
        </SectionContainer>
        <div id="schedule"></div>
        <Hero
          image="/img/cropped-course-marvin-meyer-SYTO3xs06fU-unsplash_3200x1730.jpg"
          // claim={`Dein Lern-Alltag`}
          xsTop={"180px"}
          xsLeft={"40px"}
          smTop={"180px"}
          smLeft={"150px"}
        />
        <SectionContainer>
          <Section sectionData={content.whatDoWeDo} colspan={2} />
        </SectionContainer>
        <div id="curriculum"></div>
        <Hero
          image="/img/Frau-mit-rotem-Pullover-adobe.png"
          // claim={`Curriculum`}
          opacity={0.0}
          xsTop={"20px"}
          xsRight={"calc(10px + 5vw)"}
          smTop={"180px"}
          smRight={"calc(10px + 15vw)"}
          smTextAlign={"right"}
          backgroundPosition={"25%"}
        />
        <Box sx={{ paddingTop: 3 }}>
          <h2>Curriculum</h2>
          <Box sx={{ borderBottom: 1, borderColor: "divider", paddingTop: 3 }}>
            <Tabs
              value={value}
              onChange={handleChange}
              textColor="#000"
              indicatorColor="secondary"
            >
              <Tab label="Frontend Dev Web & App" />
              <Tab label="Fullstack Developer" />
            </Tabs>
          </Box>
          {/* <div hidden={value !== 0}> */}
          {value === 0 && (
            <Section
              sectionData={content.curriculumFrontend}
              colspan={2}
              animate
            />
          )}
          {/* </div> */}
          {/* <div hidden={value !== 1}> */}
          {value === 1 && (
            <Section
              sectionData={content.curriculumFullstack}
              colspan={2}
              animate
            />
          )}
          {/* </div> */}
        </Box>

        <div id="termine"></div>
        <div id="dates"></div>
        <Hero
          image="/img/cropped-dates-jason-goodman-0K7GgiA8lVE-unsplash_3200x1730.jpg"
          // claim={`Termine`}
          xsTop={"390px"}
          xsLeft={"130px"}
          smTop={"180px"}
          smLeft={"calc(20px + 10vw)"}
          backgroundPosition={"35%"}
        />

        <Calendar
          dates={dates}
          type="students"
          onSelectInfo={() => {
            if (contactRef) {
              contactRef.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            }
          }}
        />
        <div id="beratung"></div>
        <div id="infoabend"></div>
        <div id="coaches"></div>
        <Hero
          image="/img/coach-silke-cropped.jpg"
          // claim={`Trainer\nkennenlernen`}
          opacity={0.2}
          xsTop={"250px"}
          xsLeft={"30px"}
          smTop={"20px"}
          smLeft={"30px"}
          smTextAlign={"left"}
          backgroundPosition={"70%"}
        />
        <SectionContainer ref={contactRef}>
          <Grid item xs={12} md={6}>
            <PersonalMeeting />
            {/* <SectionAlternative sectionData={content.info} /> */}
            <InfoForm />
            {/* <InfoEvening /> */}
          </Grid>
          <Grid item xs={12} md={6}>
            <Trainers
              trainerIds={[
                "silke",
                "ralf",
                "staicy",
                "ernst",
                "sascha",
                "ulrich",
              ]}
            />
            {/* <ContactForm /> */}
          </Grid>
        </SectionContainer>
        <Hero
          image="/img/cropped-tai-bui-393l7SYoM7w-unsplash_3200x1729.jpg"
          claim={`Und geschlafen\nwird am Ende\ndes Monats!`}
          opacity={0.2}
          xsTop={"20px"}
          smTop={"230px"}
          backgroundPosition={"60%"}
        />
        <Footer omit="students" />
      </Container>
    </>
  );
}

export default Students;
