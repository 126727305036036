import { StyledMarkdown } from "../StyledMarkdown/StyledMarkdown";
import { Link } from "@mui/material";
import { Grid, Box } from "@mui/material";

export const NewsSection = ({ children }) => {
  return (
    <Grid
      item
      container
      component="section"
      xs={12}
      md={12}
      spacing={[0, 0, 6]}
      sx={{
        padding: 0,
        alignContent: "flex-start",
        alignItems: "center",
      }}
    >
      <Grid
        item
        xs={12}
        md={3}
        sx={{ display: "flex", justifyContent: "center" }}
      >
        <Box sx={{ width: [140, 140, "100%"], justifyContent: "center" }}>
          <img
            style={{ width: "100%" }}
            alt="AI Village Logo"
            src="/img/logos/ai-village-logo.png"
          />
        </Box>
      </Grid>
      <Grid item xs={12} md={9}>
        <p style={{ hyphens: "auto" }}>
          Taktsoft Campus Talents ist jetzt{" "}
          <Link href="https://ai-village.eu/">
            <strong>Kooperationspartner von AI Village</strong>
          </Link>
          , dem Innovationscampus für Künstliche Intelligenz im Herzen Europas.
        </p>
        <p style={{ hyphens: "auto" }}>
          Für unsere Studentinnen und Studenten bedeutet das:{" "}
          <strong>Gastvorträge</strong> von führenden Industrieexperten,{" "}
          <strong>erlebte Forschung</strong> vor Ort und Zugang zu einem{" "}
          <strong>Netzwerk innovativer Hightech-Unternehmen</strong>.
        </p>
      </Grid>
      {/* <Grid xs={12} md={12} item sx={{ padding: 0 }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 20,
          }}
        >
          <img
            alt="AI Village Logo"
            src="/img/logos/ai-village-logo.png"
            style={{
              width: "20%",
            }}
          />
          <p>
            Taktsoft Campus Talents ist jetzt{" "}
            <strong>Kooperationspartner von AI Village</strong>, dem
            Innovationscampus für Künstliche Intelligenz im Herzen Europas. Für
            unsere Studentinnen und Studenten bedeutet das: Gastvorträge von
            führenden Industrieexperten, erlebte Forschung vor Ort und Zugang zu
            einem Netzwerk innovativer Hightech-Unternehmen.
          </p>
        </div>
      </Grid> */}
    </Grid>
  );
};

// export const SectionAlternative = ({ sectionData }) => {
//   return (
//     <Grid
//       item
//       container
//       component="section"
//       xs={12}
//       columnSpacing={5}
//       sx={{
//         alignContent: "flex-start",
//         marginBottom: 5,
//       }}
//     >
//       <Grid xs={12} item>
//         <h2>{sectionData.headline}</h2>
//       </Grid>
//       <Grid xs={12} item sx={{ padding: 0 }}>
//         <StyledMarkdown>{sectionData.columns[0]}</StyledMarkdown>
//       </Grid>
//     </Grid>
//   );
// };
