export const dates = [
  {
    date: "02.09.24 - 27.12.24",
    title: "Softwareentwickler:in Fullstack Web",
    titleShort: "Fullstack Web",
    kursnetUrl:
      "https://web.arbeitsagentur.de/weiterbildungssuche/angebot/185068888",
    infoStudents: `Du lernst, Webseiten und komplexe Web-Apps zu programmieren.
    Außerdem lernst Du, Server/Backends und Datenbanken zu programmieren, 
    um komplexe Anwendungen für viele Nutzer zu erstellen. Damit wirst
    Du zum/zur universalen Programmierer:in. 
    Außerdem bekommst Du Career-Coachings (CV, Präsentation, Bewerbung), 
    UX/Design-Trainings und lernst agiles Arbeiten - optional mit Zertifizierung 
    zum Scrum Master.`,
    infoOther: `Die Teilnehmer:innen lernen, Webseiten und komplexe Web-Apps zu programmieren.
    Außerdem Teil der Ausbildung ist die Programmierung von Servern/Backends und 
    Datenbanken, um komplexe Anwendungen für viele NutzerInnen zu erstellen. 
    UX/Design-Trainings und regelmäßiges agiles Arbeiten sind Teil der Ausbildung 
    - optional mit der Zertifizierung zum Scrum Master.`,
  },
  {
    date: "02.09.24 - 27.12.24",
    title: "Softwareentwickler:in Frontend Web & Mobile App",
    titleShort: "Web & Mobile App",
    kursnetUrl:
      "https://web.arbeitsagentur.de/weiterbildungssuche/angebot/259386961",
    infoStudents: `Du lernst, sowohl Webseiten als auch Apps für iPhone und 
    Android zu erstellen - auch komplexe Anwendungen. Damit wirst Du 
    zum/zur echten Frontend-Expert:in.  
    Außerdem bekommst Du Career-Coachings (CV, Präsentation, Bewerbung), 
    UX/Design-Trainings und lernst agiles Arbeiten - optional mit Zertifizierung 
    zum Scrum Master.`,
    infoOther: `Die Teilnehmer:innen lernen, sowohl Webseiten als auch Apps 
    und komplexe Anwendungen für Browser, iPhone und Android zu erstellen.
    UX/Design-Trainings und regelmäßiges agiles Arbeiten sind Teil der Ausbildung 
    - optional mit der Zertifizierung zum Scrum Master.`,
  },
];
